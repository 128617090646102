* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color: whitesmoke;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgb(114, 114, 247);
}

.App {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  height: 500px;
  width: 350px;
}

.span2 {
  grid-column: span 2;
}

.span-all {
  grid-column: 1 / -1;
  background-color: orangered;
  border: 1px solid black;
  word-break: break-all;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  padding: 0.5rem;
  min-height: 7rem;
}

.prev-number {
  font-size: 2.5rem;
  color: rgba(245, 245, 245, 0.709);
}

.current-number {
  font-size: 2rem;
}


button {
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 2rem;
  cursor: pointer;
}